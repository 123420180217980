import React from 'react';
import Report from './pages/Report'

function App() {
  return (
    <div >
   <Report /> 
    </div>
  );
}

export default App;
