import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";
import { withTranslation } from "react-i18next";



const styles = (theme) => ({
  downloadTitle: {
    color: "#fff",
    float: "left",
    padding: 30,
    paddingLeft: 30,
    borderRadius: "0 20px 20px 0",
    marginBottom: 50,
    marginRight: 10,
    backgroundColor: "#F43B3B",
  },
});

class FakeReportPdf extends Component {

 

  render() {
    const {classes } = this.props;
   
  
    return (
      <Grid container>
        <Grid item md={7} xs={12} className={classes.downloadTitle}>
          <Typography variant="body1" align="center">
            <strong>Loading Pdf...</strong>
          </Typography>
    </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(FakeReportPdf));
