

export function getBaseUrlPublicApi(){

  if(process.env.REACT_APP_API_DEV_MODE === "local"){
    return 'http://localhost:4000/api'
  } else if(process.env.REACT_APP_API_DEV_MODE === 'remote'){
    return 'https://report.devkube.imok.fr/api'
  }else{
    return window.location.protocol + '//' + window.location.hostname + '/api'
  }
}



export function getRequestOptions(){
  var requestOptions = {
    mode: 'cors',
    credentials: 'same-origin',
    headers: {  'Content-Type': 'application/json', 'Accept': 'application/json' },
  };


/*   if(process.env.REACT_APP_API_DEV_ALLOW_ALL_CORS === "false"){
    requestOptions = {
      mode: 'cors',
      credentials: 'same-origin',
      headers: {  'Content-Type': 'application/json', 'Accept': 'application/json', "Access-Control-Allow-Origin": "*" },
    };
  } */


  return requestOptions
}









