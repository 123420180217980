import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";
import { withTranslation } from "react-i18next";
import footerimg from "../../images/footer.png";

const styles = (theme) => ({
  footerTitle: {
    color: "#fff",
    padding: 30,
    paddingLeft: 30,
    borderRadius: "0 20px 20px 0",
    marginBottom: 30,
    backgroundColor: "#4B51CD",
  },
  gridMargin: {
    margin: "60px 0 20px 0",
  },
  footerimg:{
    maxWidth:"400px",
      width:"100%",
  }
});

class ReportFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {  classes } = this.props;

  
    return (
      <Grid
        container
        direction="row"
        className={classes.gridMargin}
        alignItems="center" 
        justify="space-between"
      >
        <Grid item md={4}>
          <Typography variant="h5" align="left" className={classes.footerTitle}>
            à la semaine prochaine!<br></br>avec I’m Ok
          </Typography>
        </Grid>
        <Grid item md={4} align="right">
          <img src={footerimg} alt="store" className={classes.footerimg}/>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(ReportFooter));
