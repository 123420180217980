import React, { Component } from "react";
//import moment from "moment/min/moment-with-locales";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";
import { withTranslation } from "react-i18next";
import logoimok from "../../images/Imoklogo.png";

const styles = (theme) => ({
  title: {
    color: "#fff",
    padding: 30,
    paddingLeft: 30,
    borderRadius: "0 20px 20px 0",
    marginBottom: 30,
    marginRight: 10,
    backgroundColor: "#4B51CD",
  },
  logoImok: {
    maxWidth: 180,
  },
  gridMargin: {
    padding: " 20px",
  },
});

class ReportHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      nameRestaurant,
      nameSite,
      weekNumber,
      startDate,
      endDate,
    } = this.props;

    const newStartDate = new Date(startDate);
    var dateOptionStart = {
      month: "long",
      day: "numeric",
    };
    let startDateFormat = new Intl.DateTimeFormat("fr-FR", dateOptionStart).format(newStartDate)

    const newEndDate = new Date(endDate);
    var dateOptionEnd = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let endDateFormat = new Intl.DateTimeFormat("fr-FR", dateOptionEnd).format(newEndDate)


    return (
      <Grid container direction="column">
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.gridMargin}
        >
          <Grid item md={6} className={classes.gridMargin}>
            <img src={logoimok} alt="logo" className={classes.logoImok} />
          </Grid>
          <Grid item md={6} className={classes.gridMargin}>
            <Typography variant="h6" align="right">
              <strong>{nameRestaurant} {nameSite!=="" ? " - " + nameSite:null}</strong>
              <br></br>
              Semaine {weekNumber}
              <br></br>
              du {startDateFormat} au {endDateFormat}
            </Typography>
          </Grid>
        </Grid>

        <Grid item md={8} className={classes.title}>
          <Typography variant="h4" align="left">
            Cette semaine dans votre Restaurant
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(ReportHeader));
