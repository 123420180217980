import React, { Component } from "react";
import * as CommonApi from "../utils/common-api";

//import { translate } from "react-i18next";

import ReportFluidity from "../components/report/ReportFluidity";
import ReportHeader from "../components/report/ReportHeader";
import ReportAffluence from "../components/report/ReportAffluence";
import ReportFooter from "../components/report/ReportFooter";
import ReportPdf from "../components/report/ReportPdf";
import FakeReportPdf from "../components/report/FakeReportPdf";
import { Typography } from "@material-ui/core";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_chart: "",
      image_chart_peaks: [],
      show_download: "false",
      show_download_part_two: "false",
    };

    // Create PDF IMAGE
    this.myRef = React.createRef();
    this.imageChartLoaded = this.imageChartLoaded.bind(this);
    this.imageChartPeakLoaded = this.imageChartPeakLoaded.bind(this);
  }

  // Call API
  componentDidMount() {
    let th = this;
    convertiJsonConc();
    function caricaFile() {
      const requestOptions = {
        ...CommonApi.getRequestOptions(),
        method: "GET",
      };
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");

      return fetch(
        CommonApi.getBaseUrlPublicApi() + `/report-affluence?code=${code}`,
        requestOptions
      );

      //return fetch(`http://localhost:3000/json/response.json`);
      // /report-affluence?code=d477a4a0b11763b8175dbcee9e4dcc34
    }

    async function convertiJsonConc() {
      const report = await caricaFile();
      const datiReport = await report.json();
      if (!report.ok) {
        th.setState({ error: true });
      } else {
        th.setState({ res: report });
        console.log(report);
        // let datiReport = await report.json();
        console.log(datiReport);

        th.setState({ stat: datiReport });
      }
    }
  }
  // Loading PDF
  imageChartLoaded(value) {
    this.setState({ image_chart: value, show_download: "true" });
  }

  imageChartPeakLoaded(values) {
    this.setState({
      image_chart_peaks: values,
      show_download_part_two: "true",
    });
  }

  render() {
    if (this.state.error) {
      return <Typography variant="h3" align="center">Il y a eu un problème </Typography>;
    }
    if (!this.state.stat) {
      return <h2>Loading...</h2>;
    }
    return (
      <section id="report">
        <ReportHeader
        nameSite={this.state.stat.site_name}
          nameRestaurant={this.state.stat.restaurant_name}
          weekNumber={this.state.stat.week_number}
          startDate={this.state.stat.start_date}
          endDate={this.state.stat.end_date}
        />

        {this.state.show_download === "true" &&
          this.state.show_download_part_two === "true" && (
            <ReportPdf
           
              imageChartPeaks={this.state.image_chart_peaks}
              imageChart={this.state.image_chart}
              reportPdf={this.state.stat}
            />
          )}

        {(this.state.show_download === "false" ||
          this.state.show_download_part_two === "false") && <FakeReportPdf />}

        <ReportFluidity
          ref={this.myRef}
          imageChartLoaded={this.imageChartLoaded}
          reportFluidity={this.state.stat.report_affluence}
        />
        <ReportAffluence
          imageChartPeakLoaded={this.imageChartPeakLoaded}
          reportAffluence={this.state.stat.report_affluence_region_peaks}
        />
        <ReportFooter />
      </section>
    );
  }
}

export default Report;
