import React, { Component } from "react";
//import moment from "moment/min/moment-with-locales";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";
import { withTranslation } from "react-i18next";

import { BarChart, Bar, XAxis, YAxis, Cell } from "recharts";
import ReactDOM from 'react-dom';

const styles = (theme) => ({
  subTitle: {
    color: "#fff",
    padding: "15px 30px 15px 60px",
    borderRadius: "0 15px 15px 0",
    backgroundColor: "#FF7A00",
    width: "fit-content",
    marginBottom: 30,
  },
  overflowstat: {
    margin: "auto",
    padding: "20px 0",
    overflowX: "scroll",
    overflowY: "hidden",
  },
  marginGrid: {
    margin: "60px 0 10px 0",
  },
  tableText: {
    display: "block",
    margin: "6px auto",
  },
  tableTextSchedule: {
    color: "#7F93A8",
    display: "block",
    margin: "6px auto",
  },

  tableTitle: {
    color: "#7F93A8",
    margin: "20px auto 6px auto",
  },
});

class ReportAffluence extends Component {
  constructor(props) {
    super(props);
    const {  reportAffluence } = this.props;
    this.myRefs = reportAffluence.map(React.createRef);
    this.myImages = reportAffluence.map(() => "");
    this.countReport = reportAffluence.length

    this.startTimer = this.startTimer.bind(this)

  }

  componentDidMount() {
    this.startTimer()
  }

  startTimer() {

    this.timer = setInterval(() =>
      this.myRefs.map((ref, index) => (this.getImage(index))), (this.countReport * 1500));
  }

  getImage(index) {
    clearInterval(this.timer)
    if (this.myRefs[index].current === null) return
    let chartSVG = ReactDOM.findDOMNode(this.myRefs[index].current).children[0];
    var serializedSVG = new XMLSerializer().serializeToString(chartSVG);
    var base64Data = window.btoa(serializedSVG);
    var image = document.createElement('img');
    var canvas = document.createElement('canvas');

    var ctx = canvas.getContext('2d');

    //const { reportAffluence } = this.props;
    //const countReport = this.countReport;

    image.src = "data:image/svg+xml;base64," + base64Data
    var myImages = this.myImages
    const { imageChartPeakLoaded } = this.props;

    image.onload = (function (iValue, canvasValue, ctxValue, myImagesValue) {
      return function () {
        canvasValue.width = this.width
        canvasValue.height = this.height
        ctxValue.drawImage(this, 0, 0);
        var dataURL = canvasValue.toDataURL();
        myImagesValue[iValue] = dataURL

        var isIncomplete = false
        myImagesValue.map((ref, index) => (ref === ""))
        myImagesValue.forEach(myImgItm => {
          if (myImgItm === "") {
            isIncomplete = true;
            return;
          }
        });

        if (isIncomplete === false) {
          imageChartPeakLoaded(myImagesValue)
        }
      }
    })(index, canvas, ctx, myImages);


    /*          image.onload = function () {
              canvas.width = image.width
              canvas.height = image.height
              ctx.drawImage(this, 0, 0);
              var dataURL = canvas.toDataURL();
              myImages[index]=dataURL
              imageChartPeakLoaded(dataURL)
            }  */
  }

  render() {
    const { classes, reportAffluence } = this.props;
    let shortDateDay;
    var i;
    var z;
    for (i = 0; i < reportAffluence.length; i++) {
      let newDates;
      for (z = 0; z < reportAffluence[i].day_peak_items.length; z++) {
        newDates = new Date(reportAffluence[i].day_peak_items[z].date_day);
        var dateOption = {
          weekday: "short",
        };
        shortDateDay = new Intl.DateTimeFormat("fr-FR", dateOption).format(
          newDates
        );
        reportAffluence[i].day_peak_items[z].shortDay = shortDateDay;
      }
    }

    function dateTransform(date) {
      let newDates = new Date(date);

      var dateOption = {
        weekday: "long",
        day: "numeric",
        month: "long",
      };
      return new Intl.DateTimeFormat("fr-FR", dateOption).format(newDates);
    }
    function timeTransform(time) {
      let newDates = new Date(time);

      var dateOption = {
        hour: "numeric",
        minute: "numeric",
      };
      return new Intl.DateTimeFormat("fr-FR", dateOption).format(newDates);
    }

   
    let arrdue = [];

    function addArray(duration) {
      arrdue.push(duration);
    }
    function colorBar(duration) {
      arrdue.push(duration);
      if (duration === Math.max(...arrdue)) {
        return "#4B51CD";
      } else {
        return "#5780FA";
      }
    }

    return (
      <Grid container direction="column">
        <Typography variant="h5" className={classes.subTitle}>
          <strong>Pics d’Affluence*</strong>
        </Typography>
        <Typography variant="h6" align="center">
          *Le pic d’affluence correspond à
          <strong> une saturation à plus de 60% </strong>de vos espaces destinés
          aux convives
        </Typography>

        <Grid container alignItems="flex-start">
          {reportAffluence.map((region, index) => (
            <Grid item key={index} md={6} xs={12}>
              <Typography
                variant="h5"
                align="center"
                className={classes.marginGrid}
              >
                {region.region_name}
              </Typography>
              <Grid container alignItems="center" justify="center">


                <BarChart
                  ref={this.myRefs[index]}
                  height={260}
                  width={400}
                  className={classes.overflowstat}
                  layout="vertical"
                  data={region.day_peak_items}
                  barCategoryGap="25%"
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis type="number" />
                  <YAxis dataKey="shortDay" type="category" />

                  <Bar
                    dataKey="duration_minutes"
                    fill="#4B51CD"
                    radius={[50, 50, 50, 50]}
                  >
                    {region.day_peak_items.map((entry, index) => (
                      <div key={index}> {addArray(entry.duration_minutes)}</div>
                    ))}
                    {region.day_peak_items.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colorBar(entry.duration_minutes)}
                      />
                    ))}

                    {region.day_peak_items.map((entry, index) => (
                      <div key={index}> {(arrdue = [])}</div>
                    ))}
                  </Bar>
                </BarChart>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={3}>
                  <Typography
                    variant="h5"
                    align="center"
                    className={classes.tableTitle}
                  >
                    Jour
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h5"
                    align="center"
                    className={classes.tableTitle}
                  >
                    Créneau Horaire
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="h5"
                    align="center"
                    className={classes.tableTitle}
                  >
                    Durée
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h5" align="center">
                {region.detail_peak_items.length < 1
                  ? "Aucun pic enregistré"
                  : ""}
              </Typography>

              {region.detail_peak_items.map((affluence,index) => (
                <div key={affluence.date_day+index}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        align="center"
                        className={classes.tableText}
                      >
                        {dateTransform(affluence.date_day)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="body1"
                        align="center"
                        className={classes.tableTextSchedule}
                      >
                        de {timeTransform(affluence.start_date)} à{" "}
                        {timeTransform(affluence.end_date)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        align="center"
                        className={classes.tableText}
                      >
                        {affluence.duration_minutes} minutes
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(ReportAffluence));
