import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";
//import { withTranslation } from "react-i18next";
import ReactDOM from 'react-dom';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Cell,
} from "recharts";

const styles = (theme) => ({
  subTitle: {
    color: "#fff",
    padding: "15px 30px 15px 60px",
    borderRadius: "0 15px 15px 0",
    backgroundColor: "#FF7A00",
    width: "fit-content",
    marginBottom: 30,
  },

  fluidityChat: {
    minWidth: "100%",
    width: "100%",
  },

  overflowstat: {
    margin: "30px auto 60px",
    padding: "20px 0",
    overflowX: "scroll",
    overflowY: "hidden",
  },
  barLabel: {
    fontSize: 24,
    fontWeight: "bold",
  },
});

class ReportFluidity extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.getImage = this.getImage.bind(this);
  }


  getImage() {
    let chartSVG = ReactDOM.findDOMNode(this.myRef.current).children[0];
    var serializedSVG = new XMLSerializer().serializeToString(chartSVG);
    var base64Data = window.btoa(serializedSVG);
    var image = document.createElement('img');
    var canvas = document.createElement('canvas');

    var ctx = canvas.getContext('2d');

    image.src = "data:image/svg+xml;base64," + base64Data

    const { imageChartLoaded } = this.props;
    image.onload = function () {
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL();
      imageChartLoaded(dataURL)
    }
  }


  //   {this.state.stat ? this.state.stat.restaurant_name: ""}

  render() {
    const { classes, reportFluidity } = this.props;
    // Value for an extra horizontal line in the Charts
    let extraCapacity = 1;

    const itemLength = reportFluidity.items.length;
    for (var i = 0; i < itemLength; i++) {
      let newDates = new Date(reportFluidity.items[i].date_day);

      var dateOption = {
        weekday: "short",
      };
      let shortDateDay = new Intl.DateTimeFormat("fr-FR", dateOption).format(
        newDates
      );
      reportFluidity.items[i].shortDay = shortDateDay;
    }
    let arrdue = [];

    function addArray(duration) {
      arrdue.push(duration);
    }
    function colorBar(duration) {
      if (duration === Math.max(...arrdue)) {
        return "#4B51CD";
      } else {
        return "#5780FA";
      }
    }
    return (
      <Grid container direction="column" className="container-fluid">
        <Typography variant="h5" className={classes.subTitle}>
          <strong>Fluiditè</strong>
        </Typography>

        <Typography variant="h6" align="center">
          Cette semaine, vous avez accueilli{" "}
          <strong>{reportFluidity.total_count}</strong> convives
        </Typography>
        <Grid container alignItems="center" justify="center">

          <BarChart
            ref={this.myRef}
            height={500}
            width={960}
            className={classes.overflowstat}
            data={reportFluidity.items}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} horizontal strokeDasharray="3 0" />
            <XAxis type="category" dataKey="shortDay" />
            <YAxis
              axisLine={false}
              type="number"
              domain={[0, `dataMax + ${extraCapacity}`]}
            />
            <Bar onAnimationEnd={this.getImage} dataKey="count" fill="#4B51CD" radius={[10, 10, 0, 0]}>
              {reportFluidity.items.map((entry, index) => (
                <div key={index}> {addArray(entry.count)}</div>
              ))}
              {reportFluidity.items.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorBar(entry.count)} />
              ))}
              <LabelList
                dataKey="count"
                position="centerTop"
                fill="#fff"
                className={classes.barLabel}
              />
            </Bar>
          </BarChart>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ReportFluidity);
