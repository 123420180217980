import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core/";
import { withTranslation } from "react-i18next";
import logoimok from "../../images/Imoklogo.png";
import chartimgoriz from "../../images/chartstesthoriz.png";
import footerimg from "../../images/footer.png";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import MontserratBold from "../../font/Montserrat-Bold.ttf"
import MontserratRegular from "../../font/Montserrat-Regular.ttf"
import MOntserratSemiBold from "../../font/Montserrat-SemiBold.ttf"

// Register font
Font.register({ family: 'Montserrat', format: "truetype" , fonts: [
  { src: MontserratRegular }, // font-style: normal, font-weight: normal
  { src: MontserratBold, fontWeight: 600 },
  { src: MOntserratSemiBold,  fontWeight: 800 },
 ]});

// Create styles Pdf
const stylesPDF = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  body: {
       fontFamily: 'Montserrat',
    padding: "0 25 20",
  },
  header: {
    padding: "5 20",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 10,
  },
  logo: {
    padding: 10,
    height: "50",
    //flexGrow: 1,
  },

  weekRestaurant: {
    lineHeight:1,
    width: "50%",
    padding: 10,
    flexGrow: 1,
    textAlign: "right",
    fontWeight: "bolder",
  },
  restaurant: {
    lineHeight:1,
    fontWeight: 600,
    textAlign: "right",
    fontSize: 11,
    color:"#363636"
  },
  week: {
    marginTop: 5,
    fontWeight: "regular",
    textAlign: "right",
    fontSize: 10,
    color:"#363636"
  },
  days: {
    marginTop: 5,
    fontWeight: "regular",
    textAlign: "right",
    fontSize: 8,
    color:"#363636"
  },

  minilogo: {
    margin: "5px 10px",
    height: "20px",
    //flexGrow: 1,
  },

  miniweekRestaurant: {
    width: "50%",
    margin: "5px 10px",
    flexGrow: 1,
    textAlign: "right",
  },
  minirestaurant: {
    fontWeight: 600,
    textAlign: "right",
    fontSize: 11,
  },
  miniweek: {
    marginTop: 5,
    fontWeight: "regular",
    textAlign: "right",
    fontSize: 10,
  },

  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  containerAffluence: {
    paddingBottom:3,
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },


  titleBlue: {
    fontSize: 17,
    width: "70%",
    color: "#fff",
    fontWeight: 400,
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: "#4B51CD",
    padding: "15px  10px 15px 30px",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  titleOrangeF: {
    width: 100,
    fontSize: 12,
    color: "#fff",
    fontWeight: 600,
    marginTop: 10,
    backgroundColor: "#FF7A00",
    padding: "6px  10px 6px 30px",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  titleOrangeP: {
    width: 180,
    fontSize: 12,
    color: "#fff",
    fontWeight: 600,
    marginTop: 0,
    backgroundColor: "#FF7A00",
    padding: "6px  10px 6px 30px",
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  chartFluidity: {
    width: "60%",
    padding: 10,
    margin: "5 20%",
  },

  centerText: {
    padding: "10px 30px",
    marginTop: 5,
    fontWeight: "regular",
    fontSize: 12,
    textAlign: "center",
  },
  centerTextAsterisc: {
    padding: "5px 10px",
    marginTop: 5,
    fontWeight: "regular",
    fontSize: 8,
    textAlign: "center",
  },
  strong: {
    fontWeight: 800,
  },
  affluenceContainer: {
    padding: "5 20",
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    alignItems: "flex-start",
    display: "flex",
  },
  chartimgoriz: {
    width: "75%",
    margin: "auto",
    padding: "0 10px",
  },
  titleChart: {
    marginTop: 10,
    textAlign: "center",
    fontSize: 10,
    fontWeight:600,
  }, 
   affluenceColumn: {
    padding: "10 20",
    width: "50%",
  },
  rowTable: {
    flexDirection: "row",
    width: "100%",
  },
  titleTableSmall: {
    width: "25%",
    textAlign: "left",
    fontSize: 8,
    color: "#7F93A8",
    marginTop: 8,
    padding: "0 5",
  }, 
   titleTable: {
    width: "35%",
    textAlign: "right",
    fontSize: 8,
    color: "#7F93A8",
    marginTop: 8,
    padding: "0 5",
  },
  titleTableLong: {
    width: "40%",
    textAlign: "center",
    fontSize: 8,
    color: "#7F93A8",
    marginTop:8,
    padding: "0 5",
  },
  textDay: {
    marginTop: 6,
    width: "35%",
    textAlign: "right",
    fontSize: 8,
    fontWeight:800,
    padding: "0 5",
  },
  textSchedule: {
    padding: "0 3",
    marginTop: 6,
    width: "40%",
    textAlign: "center",
    fontSize: 8,
    color: "#7F93A8",
  },
  textTime: {
    padding: "0 5",
    marginTop: 6,
    width: "25%",
    textAlign: "left",
    fontWeight:800,
    fontSize: 8,
  },
  paginationText: {
    position: "absolute",
    fontSize: 12,
    bottom: 25,
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    marginTop: 90,
  },
  footercontainer: {
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
    flexGrow: 1,
  },
  footer: {
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  titleFooter: {
    display: "block",
    fontSize: 10,
    width: "35%",
    height:45,
    color: "#fff",
    marginTop: 40,
    backgroundColor: "#4B51CD",
    padding: "10px  30px 10px 35px",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  footerimg: {
    textAlign: "right",
    padding: 10,
    width: "120",
    justifyContent: "flex-end",
    //flexGrow: 1,
  },
  linkDonwnload: {
    textAlign: "center",
    color: "#fff",
    fontSize: "1.6em",
    "&:hover": {
      fontWeight: "bold",
      color: "green",
      textDecoration: "none",
    },
  },
});

const styles = (theme) => ({
  downloadTitle: {
    color: "#fff",
    float: "left",
    padding: 30,
    paddingLeft: 30,
    borderRadius: "0 20px 20px 0",
    marginBottom: 50,
    marginRight: 10,
    backgroundColor: "#F43B3B",
  },
});

class ReportPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_download: false,
    };

    this.buttonLoaded = this.buttonLoaded.bind(this);
  }




  /* 
  buttonLoaded() {
    if (!this.state.show_download) {
      this.setState({ show_download: true })
    }
  } */


  buttonLoaded() {
    if (!this.state.show_download) {
      this.setState({ show_download: true })
    }
  }


  render() {
    const { classes, reportPdf, imageChart, imageChartPeaks } = this.props;
    const newStartDate = new Date(reportPdf.start_date);
    var dateOptionStart = {
      month: "long",
      day: "numeric",
    };
    let startDateFormat = new Intl.DateTimeFormat(
      "fr-FR",
      dateOptionStart
    ).format(newStartDate);

    const newEndDate = new Date(reportPdf.end_date);
    var dateOptionEnd = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let endDateFormat = new Intl.DateTimeFormat("en-GB", dateOptionEnd).format(
      newEndDate
    );
    function dateTransform(date) {
      let newDates = new Date(date);

      var dateOption = {
        weekday: "long",
        day: "numeric",
        month: "short",
      };
      return new Intl.DateTimeFormat("fr-FR", dateOption).format(newDates);
    }
    function timeTransform(time) {
      let newDates = new Date(time);

      var dateOption = {
        hour: "numeric",
        minute: "numeric",
      };
      return new Intl.DateTimeFormat("fr-FR", dateOption).format(newDates);
    }
    return (
      <Grid container>
        <Grid item md={7} xs={12} className={classes.downloadTitle}>
          <Typography variant="body1" align="center">
            <strong></strong>
          </Typography>

          <PDFDownloadLink
            style={stylesPDF.linkDonwnload}
            document={
              <Document>
                <Page size="A4" style={stylesPDF.body} >
                  <View style={stylesPDF.header} fixed>
                    <Image style={stylesPDF.logo} src={logoimok} fixed />
                    <View style={stylesPDF.weekRestaurant} fixed>
                      <Text style={stylesPDF.restaurant}>
                      {reportPdf.restaurant_name}{reportPdf.site_name!==""? " - " + reportPdf.site_name:null}
                      </Text>
                      <Text style={stylesPDF.week}>
                        Semaine {reportPdf.week_number}
                      </Text>
                      <Text style={stylesPDF.days}>
                        du {startDateFormat} au {endDateFormat}
                      </Text>
                    </View>
                  </View>
                  
                  <View style={stylesPDF.containerAffluence}>
                    <Text style={stylesPDF.titleBlue}>
                      Cette semaine dans votre Restaurant
                    </Text>
                    <Text style={stylesPDF.titleOrangeF}>Fluidité</Text>
                    <Text style={stylesPDF.centerText}>
                      Cette semaine, vous avez accueilli{" "}
                      <Text style={stylesPDF.strong}>{reportPdf.report_affluence.total_count}</Text>{" "}convives
                    </Text>
                    <Image style={stylesPDF.chartFluidity} src={imageChart} />

                    <Text style={stylesPDF.titleOrangeP}>Pics d'affluence*</Text>
                    <Text style={stylesPDF.centerTextAsterisc}>
                      *Le pic d’afﬂuence correspond à une saturation à plus de
                      60% de vos espaces destinés aux convives
                    </Text>

                    <View style={stylesPDF.affluenceContainer}>
                      {reportPdf.report_affluence_region_peaks.map((region, index) => (
                        <View style={stylesPDF.affluenceColumn} key={region.region_name+index} >
                          <Text style={stylesPDF.titleChart} >
                            {region.region_name}
                          </Text>
                          <Image
                            style={stylesPDF.chartimgoriz}
                            src= {imageChartPeaks[index] ? imageChartPeaks[index] : chartimgoriz}
                          />
                          <View style={stylesPDF.rowTable}>
                            <Text style={stylesPDF.titleTable}>Jour</Text>
                            <Text style={stylesPDF.titleTableLong}>
                              Créneau Horaire
                            </Text>
                            <Text style={stylesPDF.titleTableSmall}>Durée</Text>
                          </View>
                          <Text style={stylesPDF.titleChart} >
                            {region.detail_peak_items.length < 1
                              ? "Aucun pic enregistré"
                              : ""}
                          </Text>
                          <View style={stylesPDF.listAffluence} >
                          {region.detail_peak_items.map((affluence, index) => (
                            <View style={stylesPDF.rowTable} key={affluence.date_day+index} wrap={false}>
                              <Text style={stylesPDF.textDay}>
                                {dateTransform(affluence.date_day)}
                              </Text>
                              <Text style={stylesPDF.textSchedule}>
                                de {timeTransform(affluence.start_date)} à{" "}
                                {timeTransform(affluence.end_date)}
                              </Text>
                              <Text style={stylesPDF.textTime}>
                                {affluence.duration_minutes} min
                              </Text>
                            </View>
                          ))} 
                          
                          </View>
                        </View>
                      ))}
                    </View>
                  </View>
                 
                 

                  <View style={stylesPDF.footercontainer} wrap={false}>
                    <View style={stylesPDF.footer}>
                      <Text style={stylesPDF.titleFooter}>
                        à la semaine prochaine! avec I'm Ok
                      </Text>
                      <Image style={stylesPDF.footerimg} src={footerimg} />
                    </View>
                  </View>
                </Page>
              </Document>
            }
            fileName="aldebaran_report.pdf"
          >
            Download the Pdf
          </PDFDownloadLink>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(ReportPdf));
